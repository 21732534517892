import axios from 'axios';

export function ObtenerVentanas(payload){
	return axios.get('manager/arranques', { params: payload});
}

export function detalleVentana(idVentana){
	return axios.get(`manager/arranques/${idVentana}/detalle`);
}

export function CrearVentana(payload){
	return axios.post('manager/arranques', payload);
}

export function editarVentana(idVentana, payload){
	return axios.post(`manager/arranques/${idVentana}/editar`, payload,  { headers: { 'Content-Type': 'multipart/form-data' }, params: { _method: 'PUT'} });
}

export function eliminarVentana(idVentana){
	return axios.delete(`manager/arranques/${idVentana}/eliminar`);
}