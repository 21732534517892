<template>
	<modal ref="modalAgregarConjuntos" titulo="Agregar conjuntos" tamano="modal-md" no-aceptar adicional="Agregar" @adicional="$emit('checkList', checkList)">
		<div class="mx-3 px-2 position-sticky border-bottom bg-white z-9 top-0">
			<p class="f-light text-black mb-2">Selecciona los conjuntos que podrán ver esta ventana emergente </p>
			<el-input
			v-model="search"
			prefix-icon="icon-buscar f-18"
			placeholder="Buscar"
			size="small"
			class="w-100 mb-4"
			@input="searchCheckboxes"
			/>
			<div v-if="filteredCheckboxes.length > 0" class="d-middle justify-content-between mb-3">
				<el-checkbox
				v-model="checkAll"
				class="check-dark"
				@change="selectAll"
				>
					<span class="f-16 f-light">Seleccionar todo</span>
				</el-checkbox>
				<span class="text-blue-strong f-light f-14">{{ checkList.length }} conjuntos selecionados</span>
			</div>
		</div>
		<div class="mx-3 my-2 px-2 z-0">
			<el-checkbox-group
			v-model="checkList"
			style="display: flex; flex-direction: column"
			>
				<div class="d-flex flex-column gap-3">
					<el-checkbox
					v-for="item, index in filteredCheckboxes"
					:key="index"
					:label="item.id"
					class="check-dark"
					>
						<span class="f-16 f-light">{{ item.nombre }}</span>
					</el-checkbox>
					<p v-if="filteredCheckboxes.length === 0" class="f-14">No se encontraron coincidencias</p>
				</div>
			</el-checkbox-group>
		</div>
		<div v-if="checkList.length > 0" class="mx-3 px-2 position-sticky bg-f5 py-2 border-top z-9 bottom-0">
			<el-checkbox
			v-model="checkSelected"
			class="check-dark"
			@change="selectSelected"
			>
				<span class="f-16 f-light text-blue-strong">Ver solo conjuntos seleccionados</span>
			</el-checkbox>
		</div>
	</modal>
</template>

<script>
export default {
	props: {
		checkboxes: {
			type: Array,
			default: () => []
		},
		idConjuntos: {
			type: Array,
			default: () => []
		},
	},
	data(){
		return {
			search: '',
			checkAll: false,
			checkSelected: false,
			checkList: [],
			filteredCheckboxes: [],
		}
	},
	watch: {
		checkboxes: {
			handler(value){
				this.filteredCheckboxes = value;
			},
			deep: true
		},
		idConjuntos: {
			handler(value){
				this.checkList = value;
			},
		}
	},
	methods: {
		toggle(){
			this.$refs.modalAgregarConjuntos.toggle()
		},
		selectAll(value){
			if(value) this.checkList = this.filteredCheckboxes.map(el => el.id)
			else this.checkList = []
		},
		selectSelected(value){
			if(value) this.filteredCheckboxes = this.checkboxes.filter(el => this.checkList.includes(el.id))
			else this.searchCheckboxes()
		},
		searchCheckboxes(){
			this.checkSelected = false
			const cadena = this.search.toLowerCase()
			const coincidencias = this.checkboxes.filter(el => el.nombre.toLowerCase().indexOf(cadena) != -1)
			this.filteredCheckboxes = coincidencias.length > 0 ? coincidencias : []
		}
	}
}
</script>

<style lang="scss" scoped>
.z-0{
	z-index: 0;
}
.z-9{
	z-index: 9;
}
.top-0{
	top: 0;
}
.bottom-0{
	bottom: 0;
}
</style>