<template>
	<div class="nueva-ventana">
		<ValidationObserver ref="validator" tag="form" @submit.prevent="crearVentana" class="mb-4">
			<div class="d-flex flex-wrap gap-4">
				<div class="d-flex flex-column gap-2 wm-250px wM-392px flex-grow-1">
					<h2 class="f-medium f-20 text-black m-0 mb-4">Ventana emergente</h2>
					<ValidationProvider v-slot="{errors}" rules="required|alpha_spaces|max:60" name="nombre">
						<label for="nombre" class="f-12 pl-3">Nombre</label>
						<el-input id="nombre" v-model="model.nombre" size="small" placeholder="Ingrese el nombre"/>
						<span class="text-danger f-10">{{ errors[0] }}</span>
					</ValidationProvider>
					<div class="d-flex gap-4">
						<ValidationProvider v-slot="{errors}" rules="required" name="fecha inicio" tag="div" class="w-50">
							<label for="fecha-inicio" class="f-12 pl-3">Desde</label>
							<el-date-picker
							id="fecha-inicio"
							v-model="model.fechaInicio"
							class="w-100"
							type="time"
							size="small"
							format="dd/MM/yyyy"
							value-format="yyyy-MM-dd"
							placeholder="Fecha"
							/>
							<span class="text-danger f-10">{{ errors[0] }}</span>
						</ValidationProvider>
						<ValidationProvider v-slot="{errors}" rules="required" name="hora inicio" tag="div" class="w-50">
							<label for="hora-inicio" class="f-12 pl-3">Hora</label>
							<el-time-picker
							id="hora-inicio"
							v-model="model.horaInicio"
							class="w-100"
							size="small"
							format="HH:mm"
							value-format="HH:mm"
							placeholder="Hora"
							/>
							<span class="text-danger f-10">{{ errors[0] }}</span>
						</ValidationProvider>
					</div>
					<div class="d-flex gap-4 mb-2">
						<ValidationProvider v-slot="{errors}" rules="required" name="fecha fin" tag="div" class="w-50">
							<label for="fecha-fin" class="f-12 pl-3">Hasta</label>
							<el-date-picker
							id="fecha-fin"
							v-model="model.fechaFin"
							class="w-100"
							type="time"
							size="small"
							format="dd/MM/yyyy"
							value-format="yyyy-MM-dd"
							placeholder="Fecha"
							/>
							<span class="text-danger f-10">{{ errors[0] }}</span>
						</ValidationProvider>
						<ValidationProvider v-slot="{errors}" rules="required" name="hora fin" tag="div" class="w-50">
							<label for="hora-fin" class="f-12 pl-3">Hora</label>
							<el-time-picker
							id="hora-fin"
							v-model="model.horaFin"
							class="w-100"
							size="small"
							format="HH:mm"
							value-format="HH:mm"
							placeholder="Hora"
							/>
							<span class="text-danger f-10">{{ errors[0] }}</span>
						</ValidationProvider>
					</div>
					<div class="mb-3">
						<span class="f-light text-black f-14 pr-2">{{ !model.estado ? 'Activar' : "Inactivar" }}</span>
						<el-switch
						v-model="model.estado"
						active-color="var(--color-general)"
						/>
					</div>
					<div>
						<h2 class="f-medium f-20 text-black">Agregar conjuntos</h2>
						<p class="f-12 text-black f-light mb-3">Selecciona los conjuntos que podrán ver esta ventana emergente</p>
						<div class="d-middle flex-wrap justify-content-between gap-2">
							<div>
								<i class="icon-inmobiliaria text-black f-15"></i>
								<span class="f-light f-14 text-black ml-2">Conjuntos seleccionados: {{ model.conjuntos.length }}</span>
							</div>
							<button type="button" class="border br-4 text-f5 text-86 px-2 f-14 py-1" @click="abrirAgregarConjuntos">Seleccionar conjuntos</button>
						</div>
						<p v-if="conjuntosErrors" class="text-danger f-10">{{ conjuntosErrors }}</p>
					</div>
				</div>
				<div class="mb-4 flex-grow-1 mt-md-5 pt-md-2 wm-200px wh-200px wM-300px hM-300px">
					<label for="image" class="text-center f-12 pl-3">Imagen</label>
					<div class="br-4">
						<div class="position-relative">
							<slim-cropper
							ref="logo"
							:options="slimOptions"
							class="custom-cropper cr-pointer w-100 h-100"
							/>
							<div v-if="showCropperContent" class="cropper-content d-middle-center flex-column position-absolute">
								<div class="img mb-2"/>
								<span class="text">Arrastra una imagen</span>
								<span class="text">o</span>
								<span class="text">De clic aquí</span>
							</div>
						</div>
					</div>
					<p v-if="imgErrors" class="text-danger f-10">{{ imgErrors }}</p>
				</div>
			</div>
			<button type="submit" class="btn-general px-4 mt-4">Guardar</button>
		</ValidationObserver>
		<modal-agregar-conjuntos ref="modalAgregarConjuntos" :checkboxes="conjuntos" @checkList="seleccionarConjuntos" />
	</div>
</template>

<script>
import { CrearVentana } from '~/services/ventanas'
import Conjuntos from '~/services/conjuntos'
import modalAgregarConjuntos from './partials/modalAgregarConjuntos.vue'

export default {
	components: {
		modalAgregarConjuntos
	},
	data(){
		return {
			model: {
				imagen: '',
				nombre: '',
				fechaInicio: '',
				horaInicio: '',
				fechaFin: '',
				horaFin: '',
				estado: true,
				conjuntos: [],
			},
			conjuntos: [],
			conjuntosErrors: null,
			imgErrors: null,
			showCropperContent: true,
			slimOptions: {
                ratio: '1:1',
                label:'',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
				didLoad: (e) => {
					this.showCropperContent = false
					this.imgErrors = null;
					this.model.imagen = e
					return true
				},
				didConfirm: (e) => {
					this.$nextTick(() => {
						e.output.image.toBlob(blob => {
							this.model.imagen = new File([blob], 'logo.png', {type:'image/png'});
						});
					})
				},
				didRemove: (e) => {
					this.model.imagen = ''
					this.showCropperContent = true
					this.$nextTick(() => {
						this.imgErrors = 'Debe seleccionar una imagen'
					})
				}
            },
		}
	},
	watch: {
		"model.conjuntos"(value){
			this.conjuntosErrors = null
		}
	},
	created(){
		this.obtenerConjuntos();
	},
	methods: {
		abrirAgregarConjuntos(){
			this.$refs.modalAgregarConjuntos.toggle();
		},
		seleccionarConjuntos(conjuntos){
			this.model.conjuntos = conjuntos;
			this.$refs.modalAgregarConjuntos.toggle();
		},
		async obtenerConjuntos(){
			try {
				const { data } = await Conjuntos.listarConjuntos()
				this.conjuntos = data.data
			} catch (error){
                this.error_catch(error)
			}
		},
		async crearVentana(){
			try {
				const valid = this.$refs.validator.validate();
				if(this.model.imagen == '') this.imgErrors = 'Debe seleccionar una imagen'
				if(this.model.conjuntos.length === 0) this.conjuntosErrors = 'Debe seleccionar una imagen'
				if(this.conjuntosErrors || this.imgErrors) return;

				const formData = new FormData();
				formData.append('nombre', this.model.nombre.trim());
				formData.append('imagen', this.model.imagen)
				formData.append('fecha_inicio', `${this.model.fechaInicio} ${this.model.horaInicio}:00`)
				formData.append('fecha_fin', `${this.model.fechaFin} ${this.model.horaFin}:00`)
				formData.append('estado', this.model.estado ? 1 : 0)
				this.model.conjuntos.forEach(el => {
					formData.append('conjuntos[]', el)
				});

				const { data } = await CrearVentana(formData);

				if(!data.data) return;

				this.notificacion('','Ventana emergente correctamente','success')

				this.$router.push({ name: 'ventanas.listado' })

			} catch (error) {
                this.error_catch(error)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.nueva-ventana{
	margin: 0px 75px;
	.btn-create{
		background: transparent linear-gradient(180deg, #FFFFFF 0%, #F3F5F7 100%) 0% 0% no-repeat padding-box;
	}
}

.cropper-content{
	pointer-events: none;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	.img{
		width: 97px;
		height: 97px;
		background-image: url('/img/estados-vacios/anadir_imagen.svg');
		background-size: contain;
	}
	.text{
		color: var(--text-general);
		font-size: 11px;
	}
}
</style>